<template>
  <div class="page">
    <Header :type="'-2'" />
    <div>
      <img src="@/assets/mine/gsbj.png" />
      <div class="title">本會介绍</div>
      <div class="tips-content">
        <div class="left">
          <p>
            本會成立於1972年，為政府計冊的非牟利慈善團體。會員一萬多人，遍佈沙田及馬鞍山。隋著沙田的社區環境變化，我們的服務也隋之增加，範圍包括婦女、長者、兒童及青少年服救等。我們現有6個服務點分佈於新田村、耀安邨、源源邨、恒安邨、利安邨及沙鱼邨
            。
          </p>
          <p>
            2009年6月，更獲得民政事務署搬款，以(家家好》為名在耀安邨經营首間社企超市:2010年6月又獲環境及自然保育基金撥款經營(綠庭園環保農莊》，2012年更獲賽馬會慈善信託基金會撥款推行《婦女學堂》課程，為期3年，協助沙田區婦女自我增值，裝備自己，同時更使本會服務更趨多元化，專業化。
          </p>
        </div>
        <img class="right" src="@/assets/mine/pg1.png" />
      </div>
      <div class="tips-bottom">
        <div>
          <div class="flex">
            <img src="@/assets/mine/sm.png" class="small-img" />
            <div>
              <span class="left-title">使命</span>
              <span>維護婦女權益，促進福利善業</span>
            </div>
          </div>
          <div class="flex">
            <img src="@/assets/mine/jzg.png" class="small-img" />
            <div>
              <span class="left-title">價值觀</span>
              <span>本會堅持以下的信念: </span>
              <span>·婦女在社會的權益鹰受到保障</span>
              <span>·婦女及其家庭成員如给予所需的支援必能连致理想的發展</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header-one/index.vue";
export default {
  components: { Header },
};
</script>

<style lang="less" scoped>
.page {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  text-align: center;
  padding-bottom: 0;
  > div {
    background: #fff;
    img {
      width: 100%;
      height: 360px;
      margin-bottom: 70px;
    }
  }

  .title {
    // text-align: center;
    font-size: 18px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    margin-bottom: 25px;
  }
  .tips-content {
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    display: flex;

    .left {
      margin-right: 38px;
    }
    p {
      text-indent: 24px;
      margin-bottom: 25px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }
    .right {
      width: 457px;
      height: 258px;
    }
  }
  .tips-bottom {
    width: 100%;
    background: #f7fafd;

    // justify-content: center;
    padding: 70px 0;
    > div {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
    }
    .flex {
      display: flex;
    }

    .small-img {
      width: 126px;
      height: 126px;
      margin: 0 48px;
    }
    span {
      display: block;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      text-align: left;
    }
    .left-title {
      font-size: 22px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }
}
</style>
